exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-access-finance-js": () => import("./../../../src/pages/access-finance.js" /* webpackChunkName: "component---src-pages-access-finance-js" */),
  "component---src-pages-agriculture-and-agribusiness-js": () => import("./../../../src/pages/Agriculture-and-agribusiness.js" /* webpackChunkName: "component---src-pages-agriculture-and-agribusiness-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-covid-19-response-js": () => import("./../../../src/pages/covid-19-response.js" /* webpackChunkName: "component---src-pages-covid-19-response-js" */),
  "component---src-pages-enabling-environment-js": () => import("./../../../src/pages/enabling-environment.js" /* webpackChunkName: "component---src-pages-enabling-environment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-and-innovation-js": () => import("./../../../src/pages/investment-and-innovation.js" /* webpackChunkName: "component---src-pages-investment-and-innovation-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-newsletters-js": () => import("./../../../src/pages/newsletters.js" /* webpackChunkName: "component---src-pages-newsletters-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-our-impact-js": () => import("./../../../src/pages/our-impact.js" /* webpackChunkName: "component---src-pages-our-impact-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-policy-and-regulatory-reform-js": () => import("./../../../src/pages/policy-and-regulatory-reform.js" /* webpackChunkName: "component---src-pages-policy-and-regulatory-reform-js" */),
  "component---src-pages-product-development-js": () => import("./../../../src/pages/product-development.js" /* webpackChunkName: "component---src-pages-product-development-js" */),
  "component---src-pages-stories-76-professionals-trained-js": () => import("./../../../src/pages/stories/76-professionals-trained.js" /* webpackChunkName: "component---src-pages-stories-76-professionals-trained-js" */),
  "component---src-pages-stories-accelerating-tourism-businesses-js": () => import("./../../../src/pages/stories/accelerating-tourism-businesses.js" /* webpackChunkName: "component---src-pages-stories-accelerating-tourism-businesses-js" */),
  "component---src-pages-stories-accelerating-tunisias-tourism-growth-and-diversity-js": () => import("./../../../src/pages/stories/accelerating-tunisias-tourism-growth-and-diversity.js" /* webpackChunkName: "component---src-pages-stories-accelerating-tunisias-tourism-growth-and-diversity-js" */),
  "component---src-pages-stories-adventure-focused-tourism-js": () => import("./../../../src/pages/stories/adventure-focused-tourism.js" /* webpackChunkName: "component---src-pages-stories-adventure-focused-tourism-js" */),
  "component---src-pages-stories-authentic-tunisia-2-js": () => import("./../../../src/pages/stories/authentic-tunisia-2.js" /* webpackChunkName: "component---src-pages-stories-authentic-tunisia-2-js" */),
  "component---src-pages-stories-authentic-tunisia-campaign-js": () => import("./../../../src/pages/stories/authentic-tunisia-campaign.js" /* webpackChunkName: "component---src-pages-stories-authentic-tunisia-campaign-js" */),
  "component---src-pages-stories-bringing-30-cultural-experiences-to-the-market-js": () => import("./../../../src/pages/stories/bringing-30-cultural-experiences-to-the-market.js" /* webpackChunkName: "component---src-pages-stories-bringing-30-cultural-experiences-to-the-market-js" */),
  "component---src-pages-stories-cross-country-roadshow-js": () => import("./../../../src/pages/stories/cross-country-roadshow.js" /* webpackChunkName: "component---src-pages-stories-cross-country-roadshow-js" */),
  "component---src-pages-stories-crossboundary-agreements-js": () => import("./../../../src/pages/stories/crossboundary-agreements.js" /* webpackChunkName: "component---src-pages-stories-crossboundary-agreements-js" */),
  "component---src-pages-stories-crossboundary-js": () => import("./../../../src/pages/stories/crossboundary.js" /* webpackChunkName: "component---src-pages-stories-crossboundary-js" */),
  "component---src-pages-stories-cultural-heritage-symposium-js": () => import("./../../../src/pages/stories/cultural-heritage-symposium.js" /* webpackChunkName: "component---src-pages-stories-cultural-heritage-symposium-js" */),
  "component---src-pages-stories-ddp-impact-js": () => import("./../../../src/pages/stories/ddp-impact.js" /* webpackChunkName: "component---src-pages-stories-ddp-impact-js" */),
  "component---src-pages-stories-ddp-js": () => import("./../../../src/pages/stories/ddp.js" /* webpackChunkName: "component---src-pages-stories-ddp-js" */),
  "component---src-pages-stories-destination-development-planning-js": () => import("./../../../src/pages/stories/destination-development-planning.js" /* webpackChunkName: "component---src-pages-stories-destination-development-planning-js" */),
  "component---src-pages-stories-domestic-marketing-campaign-js": () => import("./../../../src/pages/stories/domestic-marketing-campaign.js" /* webpackChunkName: "component---src-pages-stories-domestic-marketing-campaign-js" */),
  "component---src-pages-stories-domestic-marketing-campaign-update-js": () => import("./../../../src/pages/stories/domestic-marketing-campaign-update.js" /* webpackChunkName: "component---src-pages-stories-domestic-marketing-campaign-update-js" */),
  "component---src-pages-stories-domestic-tourism-campaign-js": () => import("./../../../src/pages/stories/domestic-tourism-campaign.js" /* webpackChunkName: "component---src-pages-stories-domestic-tourism-campaign-js" */),
  "component---src-pages-stories-domestic-tourism-survey-js": () => import("./../../../src/pages/stories/domestic-tourism-survey.js" /* webpackChunkName: "component---src-pages-stories-domestic-tourism-survey-js" */),
  "component---src-pages-stories-el-jem-festival-js": () => import("./../../../src/pages/stories/el-jem-festival.js" /* webpackChunkName: "component---src-pages-stories-el-jem-festival-js" */),
  "component---src-pages-stories-ftadd-js": () => import("./../../../src/pages/stories/ftadd.js" /* webpackChunkName: "component---src-pages-stories-ftadd-js" */),
  "component---src-pages-stories-helping-sme-js": () => import("./../../../src/pages/stories/helping-sme.js" /* webpackChunkName: "component---src-pages-stories-helping-sme-js" */),
  "component---src-pages-stories-international-festival-of-matmata-js": () => import("./../../../src/pages/stories/international-festival-of-matmata.js" /* webpackChunkName: "component---src-pages-stories-international-festival-of-matmata-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-stories-kairouan-campaign-js": () => import("./../../../src/pages/stories/kairouan-campaign.js" /* webpackChunkName: "component---src-pages-stories-kairouan-campaign-js" */),
  "component---src-pages-stories-kairouan-mouled-js": () => import("./../../../src/pages/stories/kairouan-mouled.js" /* webpackChunkName: "component---src-pages-stories-kairouan-mouled-js" */),
  "component---src-pages-stories-kairouan-public-awareness-campaign-js": () => import("./../../../src/pages/stories/kairouan-public-awareness-campaign.js" /* webpackChunkName: "component---src-pages-stories-kairouan-public-awareness-campaign-js" */),
  "component---src-pages-stories-kairouan-scavenger-hunt-js": () => import("./../../../src/pages/stories/kairouan-scavenger-hunt.js" /* webpackChunkName: "component---src-pages-stories-kairouan-scavenger-hunt-js" */),
  "component---src-pages-stories-microcred-js": () => import("./../../../src/pages/stories/microcred.js" /* webpackChunkName: "component---src-pages-stories-microcred-js" */),
  "component---src-pages-stories-national-tourism-strategy-js": () => import("./../../../src/pages/stories/national-tourism-strategy.js" /* webpackChunkName: "component---src-pages-stories-national-tourism-strategy-js" */),
  "component---src-pages-stories-national-tourism-strategy-online-survey-js": () => import("./../../../src/pages/stories/national-tourism-strategy-online-survey.js" /* webpackChunkName: "component---src-pages-stories-national-tourism-strategy-online-survey-js" */),
  "component---src-pages-stories-nts-workshop-js": () => import("./../../../src/pages/stories/nts-workshop.js" /* webpackChunkName: "component---src-pages-stories-nts-workshop-js" */),
  "component---src-pages-stories-pathways-june-2023-js": () => import("./../../../src/pages/stories/pathways-june-2023.js" /* webpackChunkName: "component---src-pages-stories-pathways-june-2023-js" */),
  "component---src-pages-stories-pathways-to-professionalism-js": () => import("./../../../src/pages/stories/pathways-to-professionalism.js" /* webpackChunkName: "component---src-pages-stories-pathways-to-professionalism-js" */),
  "component---src-pages-stories-prequalification-for-construction-firms-js": () => import("./../../../src/pages/stories/prequalification-for-construction-firms.js" /* webpackChunkName: "component---src-pages-stories-prequalification-for-construction-firms-js" */),
  "component---src-pages-stories-providing-loan-and-micro-finance-grant-support-js": () => import("./../../../src/pages/stories/providing-loan-and-micro-finance-grant-support.js" /* webpackChunkName: "component---src-pages-stories-providing-loan-and-micro-finance-grant-support-js" */),
  "component---src-pages-stories-recognizing-over-350-hospitality-professionals-js": () => import("./../../../src/pages/stories/recognizing-over-350-hospitality-professionals.js" /* webpackChunkName: "component---src-pages-stories-recognizing-over-350-hospitality-professionals-js" */),
  "component---src-pages-stories-rediscovering-tunisias-vast-cultural-heritage-js": () => import("./../../../src/pages/stories/rediscovering-tunisias-vast-cultural-heritage.js" /* webpackChunkName: "component---src-pages-stories-rediscovering-tunisias-vast-cultural-heritage-js" */),
  "component---src-pages-stories-smiths-train-event-js": () => import("./../../../src/pages/stories/smiths-train-event.js" /* webpackChunkName: "component---src-pages-stories-smiths-train-event-js" */),
  "component---src-pages-stories-smithsonian-folklife-festival-js": () => import("./../../../src/pages/stories/smithsonian-folklife-festival.js" /* webpackChunkName: "component---src-pages-stories-smithsonian-folklife-festival-js" */),
  "component---src-pages-stories-smithsonian-incubator-program-js": () => import("./../../../src/pages/stories/smithsonian-incubator-program.js" /* webpackChunkName: "component---src-pages-stories-smithsonian-incubator-program-js" */),
  "component---src-pages-stories-smithsonian-incubator-program-update-js": () => import("./../../../src/pages/stories/smithsonian-incubator-program-update.js" /* webpackChunkName: "component---src-pages-stories-smithsonian-incubator-program-update-js" */),
  "component---src-pages-stories-supporting-over-280-artisans-js": () => import("./../../../src/pages/stories/supporting-over-280-artisans.js" /* webpackChunkName: "component---src-pages-stories-supporting-over-280-artisans-js" */),
  "component---src-pages-stories-sustainable-tourism-in-kairouan-js": () => import("./../../../src/pages/stories/sustainable-tourism-in-kairouan.js" /* webpackChunkName: "component---src-pages-stories-sustainable-tourism-in-kairouan-js" */),
  "component---src-pages-stories-tamss-js": () => import("./../../../src/pages/stories/tamss.js" /* webpackChunkName: "component---src-pages-stories-tamss-js" */),
  "component---src-pages-stories-technical-marketing-support-for-destination-dahar-js": () => import("./../../../src/pages/stories/technical-marketing-support-for-destination-dahar.js" /* webpackChunkName: "component---src-pages-stories-technical-marketing-support-for-destination-dahar-js" */),
  "component---src-pages-stories-ten-thousand-visitors-enjoy-the-international-oasis-festival-in-tozeur-js": () => import("./../../../src/pages/stories/ten-thousand-visitors-enjoy-the-international-oasis-festival-in-tozeur.js" /* webpackChunkName: "component---src-pages-stories-ten-thousand-visitors-enjoy-the-international-oasis-festival-in-tozeur-js" */),
  "component---src-pages-stories-tounes-lik-inspires-js": () => import("./../../../src/pages/stories/tounes-lik-inspires.js" /* webpackChunkName: "component---src-pages-stories-tounes-lik-inspires-js" */),
  "component---src-pages-stories-tounes-lik-js": () => import("./../../../src/pages/stories/tounes-lik.js" /* webpackChunkName: "component---src-pages-stories-tounes-lik-js" */),
  "component---src-pages-stories-tourism-innovation-fund-js": () => import("./../../../src/pages/stories/tourism-innovation-fund.js" /* webpackChunkName: "component---src-pages-stories-tourism-innovation-fund-js" */),
  "component---src-pages-stories-tourism-workshops-js": () => import("./../../../src/pages/stories/tourism-workshops.js" /* webpackChunkName: "component---src-pages-stories-tourism-workshops-js" */),
  "component---src-pages-stories-transformational-market-readiness-journey-js": () => import("./../../../src/pages/stories/transformational-market-readiness-journey.js" /* webpackChunkName: "component---src-pages-stories-transformational-market-readiness-journey-js" */),
  "component---src-pages-stories-upskill-js": () => import("./../../../src/pages/stories/upskill.js" /* webpackChunkName: "component---src-pages-stories-upskill-js" */),
  "component---src-pages-stories-usaid-visit-tunisia-activity-engages-300-children-js": () => import("./../../../src/pages/stories/usaid-visit-tunisia-activity-engages-300-children.js" /* webpackChunkName: "component---src-pages-stories-usaid-visit-tunisia-activity-engages-300-children-js" */),
  "component---src-pages-stories-usaid-visit-tunisia-baobab-partner-js": () => import("./../../../src/pages/stories/usaid-visit-tunisia-baobab-partner.js" /* webpackChunkName: "component---src-pages-stories-usaid-visit-tunisia-baobab-partner-js" */),
  "component---src-pages-stories-visit-tunisia-ftav-cooperation-js": () => import("./../../../src/pages/stories/visit-tunisia-ftav-cooperation.js" /* webpackChunkName: "component---src-pages-stories-visit-tunisia-ftav-cooperation-js" */),
  "component---src-pages-stories-visit-tunisia-launch-event-js": () => import("./../../../src/pages/stories/visit-tunisia-launch-event.js" /* webpackChunkName: "component---src-pages-stories-visit-tunisia-launch-event-js" */),
  "component---src-pages-stories-womens-day-js": () => import("./../../../src/pages/stories/womens-day.js" /* webpackChunkName: "component---src-pages-stories-womens-day-js" */),
  "component---src-pages-technology-and-innovation-js": () => import("./../../../src/pages/technology-and-innovation.js" /* webpackChunkName: "component---src-pages-technology-and-innovation-js" */),
  "component---src-pages-textile-and-apparel-js": () => import("./../../../src/pages/textile-and-apparel.js" /* webpackChunkName: "component---src-pages-textile-and-apparel-js" */),
  "component---src-pages-where-we-work-index-js": () => import("./../../../src/pages/where-we-work/index.js" /* webpackChunkName: "component---src-pages-where-we-work-index-js" */),
  "component---src-pages-workforce-development-js": () => import("./../../../src/pages/workforce-development.js" /* webpackChunkName: "component---src-pages-workforce-development-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */)
}

